.hovering-tilted-bar-wrapper {
  position: relative;
  padding: 50px 0;
  width: 100%;
  margin-top: -20vh;


  &:before, &:after {
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    background: red;
    content: '\00a0';
    z-index: 0;
  }

  &:before {
    top: 0;
    background-color: var(--before-background);
  }

  &:after {
    bottom: 0;
    background-color: var(--after-background);
  }

  .hovering-titled-background {
    $bgSize: 200%;

    position: absolute;
    width: 100%;
    height: $bgSize;
    top: calc(#{$bgSize} * -1 / 4);
    overflow: hidden;
    z-index: 1;
    pointer-events: none;

    &:before {
      position: absolute;
      top: 25%;
      width: 100%;
      height: 50%;
      transform-origin: center center;
      transform: rotate(-4deg) scaleX(2) scaleY(1);
      content: "\00a0";
      background: white;
      z-index: 0;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
      box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    }
  }

  &.rotation-right {
    .hovering-titled-background:before {
      transform: rotate(4deg) scaleX(2) scaleY(1);
    }
  }

  .hovering-titled-bar-contents {
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
    position: relative;
    color: #390090;
    transform: translateY(-10px);
    z-index: 2;

    h1 {
      font-size: 50px;
    }

    @media screen and (max-width: 63.9375em) {
      padding: 20px;
      text-align: center;

      h1 {
        font-size: 3ch;
      }
      h4 {
        font-size: 1ch;
      }
    }
  }
}
