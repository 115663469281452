.select-wrapper {
  margin-top: 20px;
  display: grid;

  &.disabled * {
    pointer-events: none;
  }

  .select-box-wrapper {
    order: 2;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: grid;
      gap: 5px;

      li {
        font-size: 80%;
        cursor: pointer;
        position: relative;

        &:before, &:after {
          content: "\00a0";
          height: 20px;
          width: 20px;
          margin-right: 5px;
          display: inline-block;
        }
        &:before {
          border: solid 2px var(--secondary);
          border-radius: 50%;
          transform: scale(0.7);
        }
        &:after {
          position: absolute;
          left: 0;
          transform: translateY(50%);
          opacity: 0;
          transition: all 250ms ease;
          background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 342.357 342.357" style="enable-background:new 0 0 342.357 342.357;" xml:space="preserve">        <polygon  style="fill: deeppink;" points="290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 "/></svg>');
        }

        &.selected:after {
          transform: translateY(-10%);
          opacity: 1;
        }
      }
    }

    &[data-value=""] + .select-box-label {
      opacity: .6;
    }
  }
  .select-box-label {
    margin-bottom: 5px;
    transition: all 250ms ease;
  }
}
