.switch-lottie-wrapper {
  .animation-wrapper {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .description-wrapper {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    align-items: center;
    text-align: center;
  }
}
