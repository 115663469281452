.page-header-wrapper {
  height: 50vh;
  max-height: 500px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
