.not-found-wrapper {
  padding-top: 120px;
  min-height: calc(100vh - 252px);

  h1 {
    text-align: center;
  }

  .lottie-wrapper {
    height: 500px;
    width: 500px;
    max-width: 100vw;
    margin: 0 auto;

    > * {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
