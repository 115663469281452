.textarea-wrapper {
  display: grid;
  position: relative;

  textarea {
    order: 2;
    outline: none !important;
    border: 1px none rgba(0, 0, 0, 0.5);
    border-bottom-style: solid;
    font-size: 80%;
    font-family: inherit;
    padding: 5px 0;
    resize: none;
    height: auto;
    min-height: 31px;

    &:not(.filled) + label {
      font-size: 80%;
      opacity: .6;
      transform: translateY(112%);
    }

    + label + span {
      display: block;
      content: "\00a0";
      width: 100%;
      height: 3px;
      bottom: -1px;
      left: 0;
      background: var(--primary);
      position: absolute;
      transform: scaleX(0);
      transition: all 250ms ease;
    }
    &:focus + label + span {
      transform: scaleX(1);
    }
  }
  label {
    margin-top: 20px;
    transition: all 250ms ease;
  }
}
