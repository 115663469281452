.button {
  background: var(--primary);
  border: none;
  line-height: 45px;
  border-radius: 10px;
  padding: 0 20px;
  font-size: 20px;
  outline: none !important;
  color: white;
  font-family: inherit;
  cursor: pointer;

  &.hollow {
    background: transparent;
    border: solid 2px var(--primary-dark);
    color: var(--primary-dark) !important;
  }

  &[data-progress] {
    position: relative;

    &:before {
      position: absolute;
      height: 100%;
      background: white;
      opacity: 0.5;
      left: 0;
      top: 0;
      content: "\00a0";
      overflow: hidden;
      transition: width 200ms linear;
    }

    @for $i from 0 through 100 {
      &[data-progress="#{$i}"]:before {
        width: $i * 1%;
      }
    }
  }
}
