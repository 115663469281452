.footer-wrapper {
  background: darkblue;
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 50px;
  gap: 25px;

  @media screen and (max-width: 63.9375em) {
    justify-content: start;
  }

  .footer-col {
    min-width: 20vw;
    display: flex;
    justify-content: center;

    .footer-col-contents {
      width: 200px;

      nav {
        display: grid;

        ul.footer-menu-wrapper {
          margin: 0;
          padding: 0;
          list-style: none;
          width: 100%;

          li {
            align-self: center;
            display: block;
            margin: 0;
            padding: 0;

            a {
              color: white;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
