.logo-wrapper {
  &.size-small {
    width: 50px;
    height: 50px;
  }
  &.size-big {
    width: 100px;
    height: 100px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
