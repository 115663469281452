.product-icons-wrapper {
  background: var(--primary-light);
  padding-top: 150px;
  padding-bottom: 25vh;

  ul:not(.in-viewport) {
    li {
      opacity: 0;
    }
    li:nth-child(1), li:nth-last-child(1) {
      transform: translateY(-50%);
    }
    li:nth-child(2) {
      transform: translateY(50%);
    }
    & + .cta-wrapper {
      > * {
        opacity: 0;
      }
      >:nth-child(1) {
        transform: translateX(-50%);
      }
      >:nth-last-child(1) {
        transform: translateX(50%);
      }
    }
  }

  ul {
    padding: 0 10px;
    list-style: none;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    grid-column-gap: 10px;

    li {
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-gap: 25px;
      text-align: center;
      font-weight: bold;
      color: var(--secondary);
      transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .cta-wrapper {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;

    @media screen and (max-width: 63.9375em) {
      display: grid;
      grid-template-rows: repeat(2, auto);
      gap: 25px;
      justify-items: center;
    }

    * {
      transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 500ms;
    }
  }
}

.product-content-blue-part {
  background-color: #DFEBF0;
  color: #390090;
}
.product-page-contents-wrapper {
  display: flex;
  align-items: center;
  max-width: 1024px;
  margin: 250px auto;

  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(even) >:nth-child(1) {
    order: 1;
  }
}
