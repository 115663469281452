.input-wrapper {
  display: grid;
  padding-top: 20px;
  margin-top: 15px;
  position: relative;

  > * {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  input[type="text"], input[type="email"], input[type="password"], input[type="tel"] {
    width: 100%;
    line-height: 30px;
    font-family: inherit;
    outline: none !important;
    border: 1px none rgba(0, 0, 0, 0.5);
    border-bottom-style: solid;
    padding: 0;

    + label {
      font-size: 80%;
      transform: translateY(28%);
      transition: all 250ms ease;
      width: 100%;
      opacity: .6;

      & + span {
        display: block;
        content: "\00a0";
        width: 100%;
        height: 3px;
        bottom: -1px;
        left: 0;
        background: var(--primary);
        position: absolute;
        transform: scaleX(0);
        transition: all 250ms ease;
      }
    }
    &:not([value=""]) + label {
      transform: translateY(-60%);
      font-size: 100%;
      opacity: 1;

      &:before {
        bottom: -60%;
      }
    }
    &:focus + label + span {
      transform: scaleX(1);
    }
  }
}
