.front-wrapper {
  background-color: #F9F6FD;
  min-height: 90vh;

  .scroll-part {
    min-height: 800px;

    &:nth-child(1) {
      background-color: #F9F6FD;
    }
    &.dark {
      background-color: #390090;
    }
    &.light {
      background-color: #F4EAFF;
    }
    &.blue {
      background-color: #DFEBF0;
      color: #390090;
    }
  }

  .splash-wrapper {
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 150px;
    display: grid;

    .splash-left {
      margin-top: 2vh;

      h1 {
        font-size: 5ch;
        margin-bottom: 0;
      }
      span {
        font-size: 3ch;
        color: #160146;
        position: relative;

        &:after {
          position: absolute;
          width: 0;
          height: 2px;
          background-color: #160146;
          content: "\00a0";
          left: 0;
          bottom: 0;
          animation: slide-line-in 1250ms ease-in 0s forwards;
        }
      }
    }

    @media screen and (max-width: 999.99px) {
      .splash-left {
        text-align: center;
        .logo-wrapper {
          margin: 0 auto;
        }
      }
      .splash-right {
        display: none;
      }
    }
    @media screen and (min-width: 800px) {
      .splash-left {
        justify-self: end;
        width: 400px;
      }
    }
    @media screen and (min-width: 1000px) {
      grid-template-columns: repeat(2, 50%);

      .splash-left {
        justify-self: end;
        width: 500px;
      }

    }
  }

  ul.stars {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    position: relative;

    &:before, &:after {
      content: '\00a0';
      position: absolute;
    }
    &:before {
      background: var(--secondary);
      height: 1px;
      width: 100%;
      z-index: 0;
    }
    &:after {
      background: white;
      height: 14px;
      width: calc(14px * 10);
      z-index: 1;
    }

    li {
      background-image: url('../../resources/img/ic_star_rate_14.png');
      background-size: cover;
      width: 14px;
      height: 14px;
      position: inherit;
      z-index: 2;
    }
  }

  .reviews-container {
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-column-gap: 25px;
    //align-items: center;
    font-weight: bold;
    position: absolute;

    .reviews-image-wrapper {
      background: white;
      box-shadow: 0 5px 5px 0 rgba(0,0,0,0.3);
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    p {
      transform: rotate(-4deg) translateY(30px);
    }
  }
}
@keyframes slide-line-in {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
