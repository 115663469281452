.page-wrapper {
  .page-contents-wrapper {
    position: relative;

    $surrounding-bar-height: 50px;

    &:before, &:after {
      content: '\00a0';
      background: white;
      height: $surrounding-bar-height;
      width: 100%;
      display: block;
    }
    &:before {
      clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
      margin-top: calc(#{$surrounding-bar-height} * 0.99 * -1);
    }
    &:after {
      clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
      margin-bottom: calc(#{$surrounding-bar-height} * 0.99 * -1);;
    }

    .page-contents {
      max-width: 450px;
      margin: 0 auto;
      color: var(--secondary);
      min-height: max(300px, 42vh);

      h1, h2, h3, h4, h5, h6 {
        color: var(--primary);
      }
      h1:nth-child(1) {
        margin-top: 0;
      }
      img {
        padding: 10px;
        object-fit: contain;
        margin: 0 auto;
        display: block;
      }
      table {
        width: 100%;
        img {
          width: 100%;
        }
      }

      .wp-block-columns {
        display: flex;
        gap: 50px;

        .wp-block-column {
          width: 100%;
        }
      }
    }
  }

  &.page-product {
    .page-contents {
      min-height: 0;
      width: 840px;
      max-width: 90vw;
    }
    .hovering-titled-bar-contents .page-contents-wrapper {
      border-bottom: solid 1px var(--primary);
      padding-bottom: 50px;
      margin-bottom: 50px;

      &:before, &:after {
        display: none;
      }
    }
  }
}
