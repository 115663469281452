.content-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 0;

  &.text-center {
    text-align: center;
  }
}
