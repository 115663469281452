.top-bar-wrapper {
  width: 100%;
  position: fixed;
  height: 100px;
  top: 0;
  z-index: 100;

  &:before {
    height: 100%;
    width: 100%;
    position: absolute;
    content: "\00a0";
    background: white;
    transform: translateY(-110%);
    transition: all 250ms ease;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
  }

  &.bar-showing {
    &:before, .top-bar-contents .small-logo-wrapper {
      transform: none;
      opacity: 1;
    }
  }

  .top-bar-contents {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    grid-template-rows: 100px;
    position: relative;

    > * {
      grid-row: 1 / 3;
    }
    > :nth-child(1) {
      grid-column: 1 / 3;
    }
    > :nth-child(2) {
      grid-column: 2 / 3;
    }
    > :nth-child(3) {
      grid-column: 3 / 4;
    }

    .menu-button {
      position: relative;
      grid-row: 1 / 3;
      grid-column: 1 / 3;
      width: var(--menu-button-size);
      height: calc(var(--menu-button-size) / 16 * 9);
      display: inline-block;
      align-self: center;
      margin-left: 20px;

      @media screen and (min-width: 64em) {
        display: none;
      }


      span {
        display: block;
        position: absolute;
        height: var(--menu-line-height);
        width: 100%;
        background: purple;
        transition: all 250ms ease;
        top: 0;

        &:nth-child(2) {
          top: calc(50% - var(--menu-line-height) / 2);
        }
        &:nth-child(3) {
          top: calc(100% - var(--menu-line-height));
        }
      }
    }

    nav {
      height: 100px;
      grid-column: 1/2;

      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        height: 100%;

        > li {
          height: 100%;

          > a {
            line-height: 100px;
            display: block;
            padding: 0 20px;
            text-decoration: none;
            color: var(--secondary);
          }

          &:not(:hover) ul {
            transform: scale3d(0, 0, 0) translateY(-100px);
          }

          ul {
            list-style: none;
            padding: 10px 20px;
            margin-left: 20px;
            margin-top: -20px;
            position: absolute;
            background: white;
            border-radius: 3px;
            -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
            transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transform: scale3d(1, 1, 1);
            transform-origin: top left;

            li {
              line-height: 35px;

              a {
                text-decoration: none;
                color: var(--secondary);
                position: relative;

                &:before {
                  content: "\00a0";
                  width: 100%;
                  height: 1px;
                  background: var(--secondary);
                  position: absolute;
                  bottom: -2px;
                  transition: all 250ms ease;
                }
                &:not(:hover):before {
                  width: 0;
                }
              }
            }
          }
        }
      }
    }
    .small-logo-wrapper {
      height: 100%;
      display: grid;
      align-items: center;
      justify-items: center;
      transform: translateY(-100%);
      opacity: 0;
      transition: transform 250ms ease, opacity 250ms ease 125ms;
    }
    .cta-wrapper {
      justify-self: end;
      display: flex;
      align-items: center;
      padding-right: 50px;
      gap: 25px;

      @media screen and (max-width: 63.9375em) {
        padding-right: 5px;
      }

      .phone-wrapper {
        color: var(--primary);
        font-weight: bold;

        @media screen and (max-width: 39.9375em) {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 63.9375em) {
    .top-bar-contents {
      grid-template-columns: 1fr 50px 1fr;

      nav {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background: rgba(255, 255, 255, 0.8);
        transform: translateY(-100%);
        transition: all 500ms ease;

        ul {
          display: grid;
          justify-items: center;

          li {
            font-size: 3ch;

            &.has-sub {
              position: relative;

              a {
                text-align: center;
              }

              &:after, &:before {
                position: absolute;
                content: '\00a0';
                background: var(--secondary);
                right: -2vw;
                top: 7vh;
                clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
                transition: all 250ms ease;
                height: 15px;
                width: 15px;
              }
              &:after {
                transform: rotate(180deg);
              }
              &:before {
                transform: rotate(0deg);
                opacity: 0;
              }

              &:not(:hover) {
                &:after, &:before {
                  top: 6vh;
                }
                &:after {
                  opacity: 0;
                }
                &:before {
                  opacity: 1;
                }

                ul {
                  max-height: 0;
                }
              }

              ul {
                position: static;
                overflow: hidden;
                transform: none;
                background: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
                max-height: 100vh;

                li {
                  line-height: 20px;

                  a {
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.menu-open {
      .top-bar-contents {
        nav {
          transform: none;
        }
        .menu-button {
          span {
            top: calc(50% - var(--menu-line-height) / 2);
            width: 80%;
            left: 10%;

            &:nth-child(1) {
              transform: rotate(-135deg);
            }
            &:nth-child(2), &:nth-child(3) {
              transform: rotate(135deg);
            }
          }
        }
      }
    }
  }
}
