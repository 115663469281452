.product-bar-wrapper {
  display: grid;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  overflow: hidden;

  &:nth-child(odd) * {
    color: white;
  }

  .product-bar-contents {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    justify-items: center;
    transition: all 500ms ease;

    > .product-bar-description {
      width: 50vw;
      max-width: 400px;
      justify-self: start;
      order: 2;
      align-self: center;
      transition: inherit;

      button {
        transition: all 250ms ease-out 250ms
      }
    }

    > .product-bar-illustration {
      width: 45vw;
      max-width: 650px;
      max-height: 50vh;
      order: 1;
      transition: inherit;

      * {
        height: 50vh;
        width: 100%;
      }
    }



    @media screen and (max-width: 63.9375em) {
      grid-template-columns: 1fr;
      text-align: center;

      .product-bar-description {
        max-width: 100vw;
        width: 100vw;
        transform: translateY(-50%);
        grid-row: 2 / 3;
      }
      .product-bar-illustration {
        grid-row: 1 / 2;
      }
    }
  }

  &.direction-rtl .product-bar-contents {
    .product-bar-description {
      order: 1;
      justify-self: end;
    }
    .product-bar-illustration {
      order: 2;
    }
  }

  .cls-1{fill:#ccced0;}.cls-2{fill:#f8fcff;}.cls-3{fill:#dfe3e6;}.cls-4{fill:#f4faff;opacity:0.5;}

  &:not(.active) {
    .product-bar-contents {
      > * {
        opacity: 0;
      }
      .product-bar-description {
        transform: translateX(50%);

        button {
          opacity: 0;
          transform: translateX(-50%);
        }
      }
      .product-bar-illustration {
        transform: translateY(50%);
      }
    }
    &.direction-rtl .product-bar-contents {
      .product-bar-description {
        transform: translateX(-50%);

        button {
          transform: translateX(50%);
        }
      }
    }
  }
}
