.simple-cookie-notice-wrapper {
  position: fixed;
  max-width: 90vw;
  width: 800px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 25px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
  background: white;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  z-index: 1000;

  h6 {
    font-size: 16px;
    margin: 0;
    color: var(--primary);
  }

  p {
    font-size: 12px;
    margin-bottom: 0;

    a {
      color: var(--primary);
    }
  }
  .close-button {
    height: 50px;
    width: 50px;
    position: relative;
    display: inline;
    cursor: pointer;

    &:before, &:after {
      content: "\00a0";
      position: absolute;
      width: 100%;
      height: 5px;
      top: 50%;
      left: 0;
      background: #CCC;
    }
    &:before {
      transform: translateY(-50%) rotate(-45deg);
    }
    &:after {
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
