$small-size: 100vw;
$default-size: 40vw;
$biggest-size: 500px;

.waving-girl-wrapper {
  overflow: hidden;

  .waving-girl-container {
    position: relative;
    transform: translateY(100%);
    animation: move-in 1250ms ease-in 1 0ms forwards;

    .lottie-girl-wrapper {
      overflow: hidden;
      position: relative;
      height: $small-size * 0.5;
      width: $small-size;

      @media screen and (min-width: 1000px) {
        height: $default-size;
        width: $default-size;
      }
      @media screen and (min-width: 1250px) {
        height: $biggest-size;
        width: $biggest-size;
      }

      .lottie-girl {
        transform: translate(-50%, -50%) scale(1.4);
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: 100%;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    .cilinder-wrapper {
      width: $small-size;
      height: calc(100vh - 150px - #{$small-size});
      overflow: hidden;
      position: relative;
      margin-top: -2.6vw;


      @media screen and (min-width: 1000px) {
        width: $default-size;
        height: calc(100vh - 150px - #{$default-size});
      }
      @media screen and (min-width: 1250px) {
        margin-top: calc(#{$biggest-size} / 40 * -2.6);
        width: $biggest-size;
        height: calc(100vh - 150px - #{$biggest-size});
      }

      .cilinder {
        position: absolute;
        transform-origin: top left;
        background: #F8FCFF;
        left: 5.6vw;
        width: 26vw;
        height: 1000%;
        margin-top: -$small-size;
        z-index: -1;
        top: 0;

        @media screen and (min-width: 1000px) {
          margin-top: -$default-size;
        }
        @media screen and (min-width: 1250px) {
          margin-top: -$biggest-size;
          left: calc(#{$biggest-size} / 40 * 5.6);
          width: calc(#{$biggest-size} / 40 * 26);
        }
      }
    }
  }
}

@keyframes move-in {
  0% {
    transform: translateY(100%);
  }
  95% {
    transform: translateY(-05%);
  }
  100% {
    transform: translateY(0);
  }
}
