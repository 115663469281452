.off-canvas-sidebar-wrapper {
  width: 300px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 100;
  transform: translateX(100%);
  padding: 20px;
  overflow: scroll;

  h1 {
    margin-top: 0;
  }

  .sidebar-close-button {
    position: absolute;
    right: 0;
    top: 25px;
    grid-row: 1 / 3;
    grid-column: 1 / 3;
    width: var(--menu-button-size);
    height: calc(var(--menu-button-size) / 16 * 9);
    display: inline-block;
    align-self: center;
    margin-left: 20px;

    span {
      display: block;
      position: absolute;
      height: var(--menu-line-height);
      width: 80%;
      background: var(--secondary);
      top: calc(50% - var(--menu-line-height) / 2);

      &:nth-child(1) {
        transform: rotate(-135deg);
      }
      &:nth-child(2), &:nth-child(3) {
        transform: rotate(135deg);
      }
    }
  }
}
.app-wrapper > * {
  transition: all 250ms ease;
}
.app-wrapper.sidebar-open {
  > *:not(.off-canvas-sidebar-wrapper) {
    transform: translateX(-300px);
    filter: blur(3px);
    pointer-events: none;
  }
  .off-canvas-sidebar-wrapper {
    transform: none;
  }
}
